<template>
    <div class="detail">
        <div class="bg" :style="{backgroundImage:`url(${data.banner})`}"></div>
        <div class="container">
            <div class="container-box">
                <div class="top-title">{{data.name}}</div>
                <div class="top-desc">
                   {{data.desc}}
                </div>
            </div>

            <div class="container-box">
                <div class="title flex">
                    <img class="icon-img" src="https://khome2.tuzuu.com/vita/de_1.png?v=1"/>
                    <span>功效</span>
                </div>
                <div class="hr"></div>
                <ul class="list">
                    <li v-for="(item,index) in $$(data.effect).split(/[(\r\n)\r\n]+/)" :key="index">
                        <span>{{item}}</span>
                    </li>
                </ul>
            </div>

            <div class="container-box">
                <div class="title flex" style="margin-bottom: 22px">
                    <img src="https://khome2.tuzuu.com/vita/de_2.png" class="icon-img">
                    营养成分
                </div>
                <div class="nutrition">
                    <div class="nutrition-title flex-between">
                        <span>营养成分</span>
                        <span>含量 / 颗</span>
                    </div>
                    <div class="con">
                        <div class="con-item flex-between" v-for="(item,key,index) in data.pri_ingredient" :key="index">
                            <span>{{key}}</span>
                            <span>{{item}}</span>
                        </div>

                    </div>
                </div>
            </div>

            <div class="container-box">
                <div class="title flex-between">
                    <div class="flex">
                        <img src="https://khome2.tuzuu.com/vita/de_3.png" class="icon-img">
                        安全认证
                    </div>

                    <router-link to="/quality" class="info flex">
                        详细了解
                        <van-icon name="arrow" color="#666666" style="transform: translateY(1px)"/>
                    </router-link>
                </div>
                <div class="hr"></div>
                <div class="seru">
                    <div class="flex">
                        <div class="seru-item flex-column-center" >
                            <img src="https://khome2.tuzuu.com/vita/ren_1.png?v=3">
                            <span>GMP</span>
                        </div>
                        <div class="seru-item flex-column-center" >
                            <img src="https://khome2.tuzuu.com/vita/ren_2.png">
                            <span>FDA</span>
                        </div>
                        <div class="seru-item flex-column-center" >
                            <img src="https://khome2.tuzuu.com/vita/ren_3.png">
                            <span>SGS</span>
                        </div>
                    </div>
                </div>

                <div class="title flex">
                    <img src="https://khome2.tuzuu.com/vita/de_4.png" class="icon-img">
                    注意事项
                </div>
                <div class="hr"></div>
                <div class="desc-info" style="margin-bottom: 40px">
                    {{data.notice}}
                </div>
                <div class="title flex">
                    <img src="https://khome2.tuzuu.com/vita/de_5.png" class="icon-img">
                    如何服用
                </div>
                <div class="hr"></div>
                <div class="desc-info">
                   {{data.usage}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "",
        data(){
            return{
                data:''
            }
        },
        created() {
            let toast = this.$toast.loading({
                message: '加载中',
                forbidClick: true,
                overlay:true
            });
            let id = this.$route.params.id
            this.$request.get("product_detail",{
                product_id:id
            }).then(res => {
                if(res.code === 1){

                    this.data = res.data
                    toast.clear()
                    document.title = this.data.name
                    console.log(this.data.name)
                }

            })
        }
    }
</script>

<style scoped lang="less">
    .detail {
        width: 100%;
        min-height: 100vh;
        background-color: #F3F3F4;

        .bg {
            width: 100%;
            height: 200px;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }
    }

    .container {
        margin-top: -120px;
        padding: 0px 20px;
        box-sizing: border-box;
        padding-bottom: 20px;
        .container-box {
            background-color: white;
            border-radius: 5px;
            padding: 30px 20px;

            box-sizing: border-box;
            margin-bottom: 15px;
            .top-title {
                font-size: 21px;
                width: fit-content;
                padding-bottom: 12px;
                border-bottom: 2px solid #292C2F;
            }


            .top-desc {
                font-size: 12px;
                color: #666666;
                line-height: 25px;
                padding-top: 20px;
                letter-spacing: 1px;
            }

            .title {
                font-size: 18px;
                color: #292C2F;

                font-weight: 700;
                .icon-img {
                    width: 22px;
                    margin-right: 7px;
                    //margin-left: -3px;
                }
                .info{
                    font-size: 14px;
                    color: #666666;
                    font-weight: 400;
                }

            }
            .hr{
                width: 22px;
                height: 2px;
                background-color: black;
                margin-top: 14px;
                margin-bottom: 16px;
            }

            .list {
                font-weight: 500;
                font-size: 12px;
                color: #636465;
                line-height: 25px;
                letter-spacing: 1px;
                li {
                    display: flex;
                    align-items: baseline;
                    &::before {
                        content: '';
                        display: inline-block;
                        width: 4px;
                        height: 4px;
                        background-color: white;
                        transform: translateY(-2px);
                        border: 1px solid #666666;
                        border-radius: 50%;
                        margin-right: 7px;
                    }
                    span{
                        width: 100%;
                    }
                }
            }

            .nutrition{
                font-size: 12px;
                .nutrition-title{
                    padding: 15px 0px;
                    border-top:4px solid #292C2F;
                    border-bottom: 1px solid #292C2F;

                    color: #666666;
                }
                .con{
                    color: #666666;

                    padding: 0px 0px;
                    border-bottom:4px solid #292C2F;
                    line-height: 25px;
                   .con-item{
                       margin: 13px 0px;
                   }
                }

            }

            .seru{
                padding-bottom: 10px;

                margin-bottom: 35px;
                .seru-item{
                    margin-right: 50px;
                    font-size: 15px;

                    color: #666666;
                    img{
                        width: 40px;
                        margin-bottom: 15px;
                    }
                }
                .info{
                    justify-content: flex-end;
                    margin-top: 30px;
                    font-size: 14px;

                    color: #666666;
                }
            }
            .desc-info{
                font-size: 12px;

                color: #636465;
                letter-spacing: 1px;
                box-sizing: border-box;
            }
        }
    }
</style>